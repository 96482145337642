<template>
  <v-card
    v-if="user.email != session[userType].email"
    flat
    class="pa-md-8"
    width="100%"
    height="100%"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <AlertBox :message="error" class="ma-1 mb-2" />
        <v-alert
          v-if="isPermissionError"
          dark
          class="black--text text-wrap text-subtitle-2 ma-1 mb-2"
          color="light_error"
        >
          {{ isPermissionError }}
        </v-alert>
        <v-card class="mx-auto mt-n2" tile elevation="0">
          <v-list-item two-line>
            <v-list-item-avatar tile class="rounded" size="48">
              <v-img
                :src="
                  session[userType].profileImg
                    ? session[userType].profileImg
                    : $defaultImg
                "
              />
            </v-list-item-avatar>
            <v-list-item-content class="text-left mt-n3">
              <v-list-item-title class="text-subtitle-1 mb-1">
                {{ session[userType].firstName }}
                {{ session[userType].lastName }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-subtitle-2 black--text">
                {{ session.sessionType.title }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon class="mt-10">
              <v-btn
                v-if="!disableJoin"
                color="yellow"
                class="no-shadow"
                :loading="loading"
                :disabled="disableJoinButton"
                @click="disableJoinButton ? '' : joinEvent()"
              >
                {{ $t('join_sessn') }}
              </v-btn>
              <v-btn
                v-else
                color="red"
                class="ml-4 no-shadow"
                @click="
                  leaveEvent()
                  markedSessionCompleted()
                "
              >
                <v-icon>mdi-close</v-icon>
                {{ $t('end_sessn') }}
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12">
        <fullscreen ref="fullscreen">
          <v-card :color="!remoteStreams.length ? 'black' : null" class="mx-4">
            <v-card-title
              v-show="localStream && isVideoOn"
              class="client-video"
            >
              <AgoraVideo
                v-if="localStream && !isScreenShare"
                :stream="localStream"
                :dom-id="localStream.getId()"
              />
            </v-card-title>
            <v-card-title
              v-show="!localStream || !isVideoOn || isScreenShare"
              class="client-video"
            >
              <img
                width="100"
                height="100"
                class="rounded profile-avatar"
                :src="profileImage"
              />
            </v-card-title>
            <v-card-title class="session-countdown" v-if="!sessionSatarted">
              Session starts in
              <v-card-text class="session-countdown-time">
                {{ timeFormater(startCounter) }}
              </v-card-text>
            </v-card-title>
            <v-card-text
              :class="
                fullscreen
                  ? 'grow host-full-video pa-0 ma-0'
                  : 'grow host-video pa-0 ma-0'
              "
            >
              <AgoraVideo
                v-if="remoteStreams.length"
                :stream="remoteStreams[0]"
                :dom-id="remoteStreams[0].getId()"
                :fullscreen="fullscreen"
                :is-client="false"
              />
            </v-card-text>
            <v-card-actions class="pb-5">
              <v-row
                id="custom-disabled"
                justify="space-between"
                align="center"
              >
                <v-col>
                  <v-btn color="yellow" class="ml-2 white--text">
                    {{displayTime()}}
                  </v-btn>
                </v-col>

                <v-col class="tooltip-buttons">
                  <!-- Voice mute/unmute buttton -->
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        color="white"
                        class="no-shadow ma-1"
                        :disabled="!disableJoin"
                        height="48"
                        width="48"
                        x-small
                        v-on="on"
                        @click="muteAudio"
                      >
                        <img
                          height="25"
                          width="25"
                          :src="
                            isAudioOn
                              ? '/images/icon/mic.png'
                              : '/images/icon/mic_off.png'
                          "
                        />
                      </v-btn>
                    </template>
                    <span>{{ isAudioOn ? $t('unmute') : $t('mute') }}</span>
                  </v-tooltip>
                  <!-- Voice mute/unmute buttton -->

                  <!-- Video on/off buttton -->
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        color="white"
                        class="no-shadow ma-1"
                        :disabled="!disableJoin"
                        height="48"
                        width="48"
                        x-small
                        v-on="on"
                        @click="muteVideo"
                      >
                        <img
                          height="25"
                          width="25"
                          :src="
                            isVideoOn
                              ? '/images/icon/cam.png'
                              : '/images/icon/cam_off.png'
                          "
                        />
                      </v-btn>
                    </template>
                    <span>{{
                      isVideoOn ? $t('camera_on') : $t('camera_off')
                    }}</span>
                  </v-tooltip>
                  <!-- Video on/off buttton -->

                  <!-- Share Screen on/off buttton -->
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        color="white"
                        class="no-shadow ma-1"
                        :disabled="!disableJoin"
                        :loading="shareLoading"
                        height="48"
                        width="48"
                        x-small
                        v-on="on"
                        @click="screenShare"
                      >
                        <img
                          height="25"
                          width="25"
                          :src="
                            isScreenShare
                              ? '/images/icon/share.png'
                              : '/images/icon/share_off.png'
                          "
                        />
                      </v-btn>
                    </template>
                    <span>{{
                      isScreenShare
                        ? $t('share_screen_on')
                        : $t('share_screen_off')
                    }}</span>
                  </v-tooltip>
                  <!-- Share Screen on/off buttton -->

                  <!--Chat buttton -->
                  <v-menu
                    v-model="chatDialog"
                    :close-on-click="false"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    top
                    offset-y
                    :nudge-top="10"
                  >
                    <template #activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            color="white"
                            class="no-shadow ma-1"
                            :disabled="!disableJoin"
                            height="48"
                            width="48"
                            x-small
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                          >
                            <v-icon v-if="chatDialog" color="black">
                              mdi-close
                            </v-icon>
                            <v-icon v-else color="black">
                              mdi-message
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Chat</span>
                      </v-tooltip>
                    </template>
                    <!-- Chat Component -->
                    <AgoraChat
                      v-if="chatDialog"
                      :chat-dialog="chatDialog"
                      :options="chatOption"
                      @scroll-bottom="scrollToBottom()"
                      @showAlert="showAlert"
                    />
                  </v-menu>
                  <!--Chat buttton -->

                  <!--Fullscreen on/off buttton -->
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        color="white"
                        class="no-shadow ma-1 mr-3"
                        :disabled="!disableJoin"
                        height="48"
                        width="48"
                        x-small
                        v-on="on"
                        @click="toggleScreen"
                      >
                        <v-icon v-if="fullscreen" color="black">
                          mdi-fullscreen
                        </v-icon>
                        <v-icon v-else color="black">
                          mdi-fullscreen-exit
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      fullscreen ? $t('full_screen_on') : $t('full_screen_off')
                    }}</span>
                  </v-tooltip>
                  <!--Fullscreen on/off buttton -->
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </fullscreen>
      </v-col>
    </v-row>
    <v-row v-if="disableJoin && userType == 'guest'" class="mx-auto mb-3">
      <v-col cols="12">
        <v-card color="grey_regular" flat class="rounded pa-3">
          <v-row align="center">
            <v-flex xs12 md4>
              <v-card-text class="text-subtitle-1">
                {{ $t('increase') }} <br />{{ $t('session_sml') }}?
              </v-card-text>
            </v-flex>
            <v-flex xs12 md4 text-center class="d-flex">
              <v-text-field
                v-model="increaseMin"
                class="input-error mr-2"
                outlined
                required
                hide-details="auto"
                height="50px"
                suffix="min"
              />
              <v-text-field
                v-model="increasePrice"
                class="input-error"
                outlined
                required
                hide-details="auto"
                height="50px"
                suffix="$"
              />
            </v-flex>
            <v-flex xs12 md4 text-right>
              <v-menu
                offset-y
                top
                left
                :close-on-content-click="false"
                class="card-selection"
              >
                <template #activator="{ attrs }">
                  <v-btn
                    v-bind="attrs"
                    color="white"
                    text
                    depressed
                    :ripple="false"
                    class="text-capitalize increase_btn mr-2"
                    :loading="increaseLoading"
                    :disabled="validateIncrease()"
                    @click="sendExtendAlert()"
                  >
                    {{ $t('increase_session') }}
                  </v-btn>
                </template>
              </v-menu>
            </v-flex>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="disableJoin && userType == 'host'"
      justify="center"
      class="mx-auto mb-3"
    >
      <v-col cols="12" class="d-flex justify-center">
        <v-dialog
          v-model="isShowCard"
          offset-y
          top
          left
          :close-on-content-click="false"
          class="card-selection"
          width="500"
        >
          <template #activator="{ attrs }">
            <v-btn
              v-bind="attrs"
              color="yellow"
              class="ml-2 white--text mt-5"
              :disabled="!extendSessionAlert"
              v-on="isShowCard"
              @click="showCardDetails()"
            >
              {{ $t('increase_session') }}?
            </v-btn>
          </template>
          <v-card v-if="isShowCard" color="white" rounded flat class="pa-2">
            <v-card-title v-if="!addCardDialog" class="text-h3 pb-3">
              {{ $t('increase_session') }}
            </v-card-title>
            <v-card-text
              v-if="!addCardDialog"
              class="text-subtitle-1 orange--text pt-0 py-2 mb-3"
            >
              Add {{ extendTimeMin }} mins./ ${{ extendTimePrice }}
            </v-card-text>
            <v-card-text
              v-if="!addCardDialog"
              class="text-subtitle-1 pb-0 black--text"
            >
              {{ $t('payment_method') }}
            </v-card-text>
            <v-card-text class="pt-0">
              <v-row class="card-selection" align="center">
                <v-col xs="12" sm="8" md="12" ls="12" mt-1>
                  <stripe-element-card
                    ref="stripeCardRef"
                    :disabled="loading"
                    :hide-postal-code="true"
                    :pk="stripePublishKey"
                    @token="payAndExtendSession"
                  />
                  <AlertBox :message="stripeError" class="ma-1 mb-2" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="text-center pa-0">
              {{ $t('or_pay') }}
            </v-card-text>
            <v-card-text class="text-center pa-3">
              <router-link to="#">
                <img
                  class="profile-image"
                  src="@/assets/images/icons/pay.png"
                  eager
                />
              </router-link>
              <router-link to="#">
                <img
                  class="profile-image"
                  src="@/assets/images/icons/pay_2.png"
                  eager
                />
              </router-link>
            </v-card-text>
            <!-- END: add card -->

            <v-card-text v-if="!addCardDialog" class="text-right pt-0">
              <v-btn
                text
                right
                :ripple="false"
                :disabled="extendLoading"
                @click="isShowCard = !isShowCard"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn
                right
                depressed
                color="primary"
                :ripple="false"
                class="ml-3 no-shadow"
                :loading="extendLoading"
                @click="$refs.stripeCardRef.submit()"
              >
                {{ $t('make_payment') }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="show"
      :timeout="5000"
      :color="isError ? 'red' : 'success'"
    >
      {{ text }}
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import { isEmpty } from 'lodash'
import { STRIPE_PUBLISHABLE_KEY } from '../../consts'

//utils
import { delay } from '@/utils/helpers'
import i18n from '@/utils/i18n.js'

// graphql
import { GET_VIDEO_AUTH_TOKEN } from '@/graphql/video'
import { MARK_SESSION_COMPLETED } from '@/graphql/sessions'
import { PAY_AND_EXTEND_SESSION, EXTEND_SESSION_ALERT } from '@/graphql/booking'

// Video
import RTCClient from '@/agora/video/rtcClient'
import { log } from '@/agora/video/log'
import AgoraVideo from '@/agora/video/Video'

// Chat
import AgoraChat from '@/agora/chat/Chat'

export default {
  components: {
    AgoraVideo,
    AgoraChat,
    StripeElementCard,
  },
  props: {
    session: {
      type: Object,
      default: () => {},
      required: true,
    },
    userType: {
      type: String,
      default: () => null,
      required: true,
    },
  },
  data() {
    return {
      stripePublishKey: STRIPE_PUBLISHABLE_KEY,
      option: {
        appid: '5042fbb7676e479c9f81f553b8b59620',
        token: null,
        uid: null,
        channel: null,
        host: true,
      },
      chatOption: {
        appid: '5042fbb7676e479c9f81f553b8b59620',
        token: null,
        uid: null,
        channel: null,
        userName: null,
        msgChannel: null,
      },
      extendTimeMin: 0,
      extendTimePrice: 0,
      disableJoin: false,
      localStream: null,
      remoteStreams: [],
      streamId: null,
      loading: false,
      error: null,
      show: false,
      text: null,
      isError: false,
      chatDialog: false,
      shareLoading: false,
      fullscreen: false,
      isScreenShare: false,
      isPermissionError: null,
      selectedSlot: null,
      isShowCard: false,
      userCardDetails: [],
      selectedCard: null,
      enterFullScreen: false,
      disableJoinButton: true,
      intervalToken: null,
      addCardDialog: false,
      stripeError: null,
      extendError: null,
      extendLoading: false,
      endTime: null,
      sessionSatarted: false,
      startCounter: 0,
      durationCounter: 0,
      increaseMin: 0,
      increasePrice: 0,
      increaseLoading: false,
      extendSessionAlert: false,
      sessionDurationTime: 0,
    }
  },
  mounted() {
    // Set session duration time when page render
    this.sessionDurationTime = this.$moment(this.session.endTime).diff(
      this.session.startTime,
      'minutes'
    )
  },
  computed: {
    ...mapGetters(['profileImage', 'user', 'jwt']),
    isAudioOn() {
      return this.localStream && this.localStream.isAudioOn() ? true : false
    },
    isVideoOn() {
      return this.localStream && this.localStream.isVideoOn() ? true : false
    },
  },
  watch: {
    session(val, old) {
      // console.log(val,old)
      if (val.index !== old.index) {
        // When change session in list, change duration time
        this.sessionDurationTime = this.$moment(this.session.endTime).diff(
          this.session.startTime,
          'minutes'
        )
        this.endTime = val.endTime
        this.isValidTimeToJoin()

        if (this.intervalToken) {
          this.endInterval()
        }

        this.startInterval()
      }
    },
  },
  created() {
    console.log(this.session)
    this.endTime = this.session.endTime
    this.isValidTimeToJoin()
    this.startInterval()

    document.addEventListener('fullscreenchange', () => {
      if (this.enterFullScreen) {
        this.fullscreen = false
      }
      this.enterFullScreen = !!this.fullscreen
    })

    this.rtc = new RTCClient()
    let { rtc } = this
    rtc.on('stream-added', evt => {
      let { stream } = evt
      log('[agora] [stream-added] stream-added', stream.getId())
      rtc.client.subscribe(stream)
    })

    rtc.on('stream-subscribed', evt => {
      let { stream } = evt
      log('[agora] [stream-subscribed] stream-added', stream.getId())
      if (!this.remoteStreams.find(it => it.getId() === stream.getId())) {
        this.remoteStreams.push(stream)
      }
    })

    rtc.on('stream-removed', evt => {
      let { stream } = evt
      log('[agora] [stream-removed] stream-removed', stream.getId())
      this.remoteStreams = this.remoteStreams.filter(
        it => it.getId() !== stream.getId()
      )
    })

    rtc.on('peer-online', evt => {
      log(`Peer ${evt.uid} is online`)
    })

    rtc.on('peer-leave', evt => {
      log(`Peer ${evt.uid} already leave`)
      this.remoteStreams = this.remoteStreams.filter(
        it => it.getId() !== evt.uid
      )
    })

    var channel = this.$pusher.subscribe(this.user.email)
    channel.bind('recieve_extended_token', extendedDetails => {
      this.endTime = extendedDetails.data.endtime
      this.chatOption.token = extendedDetails.data.texttoken
      this.option.token = extendedDetails.data.videotoken
      // Update session duration time when session extended
      this.sessionDurationTime = this.$moment(
        extendedDetails.data.endtime
      ).diff(this.session.startTime, 'minutes')
    })

    // if host send extend session alert, avaliable increase session button in member side
    channel.bind('extend_alerted', extendSessionAlert => {
      if (extendSessionAlert) this.extendSessionAlert = true
      this.extendTimeMin = extendSessionAlert.data.extend_session.length
      this.extendTimePrice = extendSessionAlert.data.extend_session.charge
    })
  },
  beforeDestroy() {
    this.endInterval()
    if (this.sessionSatarted) {
      this.leaveEvent()
      //call session completed
      this.markedSessionCompleted()
    }
  },
  methods: {
    startInterval() {
      this.intervalToken = setInterval(() => {
        this.isValidTimeToJoin()
      }, 1000)
    },
    endInterval() {
      clearInterval(this.intervalToken)
      this.intervalToken = null
    },
    twoDigitFormater(d) {
      return d < 10 ? '0' + d.toString() : d.toString()
    },
    // Validate increase session minute and price
    validateIncrease() {
      if (this.increaseMin === '' || this.increasePrice === '') return true
      else if (
        parseInt(this.increaseMin) === 0 ||
        parseInt(this.increasePrice) === 0
      )
        return true
      else return false
    },
    timeFormater(sec_num) {
      var hours = Math.floor(sec_num / 3600)
      var minutes = Math.floor((sec_num - hours * 3600) / 60)
      var seconds = sec_num - hours * 3600 - minutes * 60

      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (seconds < 10) {
        seconds = '0' + seconds
      }
      return hours + ':' + minutes + ':' + seconds
    },
    isValidTimeToJoin() {
      // var beforeStartTime = this.$moment(this.session.startTime).subtract(
      // 2,
      // 'minutes'
      // )

      var sessionStartTime = this.$moment(this.session.startTime).diff(
        this.$moment(),
        'seconds'
      )
      var sessionEndTime = this.$moment(this.endTime).diff(
        this.$moment(),
        'seconds'
      )
      if (!this.sessionSatarted && sessionStartTime >= 0) {
        this.startCounter = sessionStartTime
      } else this.startCounter = 0

      if (this.sessionSatarted && sessionStartTime < 0) {
        this.durationCounter = -sessionStartTime
      } else this.durationCounter = 0

      if (sessionStartTime <= 0 && sessionEndTime >= 0) {
        this.disableJoinButton = false
      } else {
        this.disableJoinButton = true
      }

      if (sessionEndTime <= 0) {
        if (this.sessionSatarted) {
          // this.endInterval()
          this.leaveEvent()
          this.markedSessionCompleted()
        }
      }
    },
    displayTime() {
      let diffSeconds = this.sessionDurationTime*60-this.durationCounter;
      if(diffSeconds > 60) return `${Math.floor(this.durationCounter / 60)}:${this.twoDigitFormater(this.durationCounter % 60)}
                    / ${ this.sessionDurationTime }:00`;
      else return diffSeconds;
    },
    /**
     * Join The Channel
     */
    async joinEvent(isAudio = true, isVideo = true, isScreen = false) {
      this.disableSessionLists(true)
      if (isScreen) {
        this.isScreenShare = true
        this.shareLoading = true
        isAudio = true
        isVideo = false
      } else {
        this.isScreenShare = false
        this.loading = true
        isAudio = true
        isVideo = true
      }
      this.error = null
      // try {
      const {
        data: { generateVideoAuth },
      } = await this.$apollo.mutate({
        mutation: GET_VIDEO_AUTH_TOKEN,
        variables: {
          authData: {
            email: this.user.email,
            accesstoken: this.jwt,
            sessionid: Number(this.session.id),
          },
        },
      })

      if (generateVideoAuth.success) {
        this.loading = false
        this.shareLoading = false

        // Chat Options
        this.chatOption.msgChannel = generateVideoAuth.session.textchannel
        this.chatOption.channel = this.user.email
        this.chatOption.token = generateVideoAuth.texttoken
        this.chatOption.uid = this.user.id
        this.chatOption.userName = this.user.email

        // Video Options
        this.option.channel = generateVideoAuth.session.videochannel
        this.option.token = generateVideoAuth.videotoken
        this.option.uid = this.user.id
        this.option.userName = `${this.user.firstName} ${this.user.lastName}`

        if (!this.option.appid) {
          this.disableSessionLists(false)
          this.judge('Appid')
          return
        }
        if (!this.option.channel) {
          this.disableSessionLists(false)
          this.judge('Channel Name')
          return
        }
        await this.rtc
          .joinChannel(this.option)
          .then(() => {
            log('Join Success')
            this.rtc
              .publishStream({
                audio: isAudio,
                video: isVideo,
                screen: isScreen,
              })
              .then(stream => {
                log('Publish Success')
                this.localStream = stream
                // set video resoultion 3840 × 2160, Frame rate: 60, Bitrate: 13500
                if (!this.isScreenShare) {
                  this.localStream.setVideoProfile('4K_3')
                }
              })
              .catch(err => {
                this.isPermissionError = i18n.t('permission_error_txt')
                log('publish local error', err)
              })
          })
          .catch(err => {
            log('join channel error', err)
          })
        this.sessionSatarted = true
        this.disableJoin = true
        this.fullscreen = false
        this.localStream.unmuteAudio()
      } else {
        this.disableSessionLists(false)
        this.error = this.$t(generateVideoAuth.error)
        this.loading = false
        this.shareLoading = false
        await delay(2000)
        this.error = null
      }
      // } catch (e) {
      // this.loading = false
      // console.error(e); // eslint-disable-line
      // }
    },

    /**
     * Leave The Channel
     */
    leaveEvent() {
      this.disableSessionLists(false)
      this.disableJoin = false
      this.rtc
        .leaveChannel()
        .then(() => {
          log('Leave Success')
        })
        .catch(err => {
          log('leave error', err)
        })
      this.localStream = null
      this.isPermissionError = null
      this.remoteStreams = []
      this.chatDialog = false
      this.sessionSatarted = false
      this.$store.commit('setChatMessages', null)
      this.increaseMin = 0
      this.increasePrice = 0
      this.extendSessionAlert = false
      window.rtm = null
    },

    /**
     * Increase session with card number in member side
     */
    async payAndExtendSession(token) {
      this.stripeError = null
      this.extendLoading = true
      try {
        if (!isEmpty(token)) {
          const {
            data: { payAndExtendSession },
          } = await this.$apollo.mutate({
            mutation: PAY_AND_EXTEND_SESSION,
            variables: {
              extensionData: {
                email: this.user.email,
                accesstoken: this.jwt,
                cardtoken: token.id,
                length: this.extendTimeMin,
                charge: this.extendTimePrice,
                bookedsessionid: this.session.id,
                tzinfo: this.$defaultTZ,
              },
            },
          })
          if (payAndExtendSession.success) {
            this.isShowCard = !this.isShowCard
            this.increaseMin = 0
            this.increasePrice = 0
            this.extendSessionAlert = false
          } else {
            this.extendError = this.$t(payAndExtendSession.error)
          }
          this.extendLoading = false
        }
      } catch (e) {
        this.loading = false
        console.log(e) // eslint-disable-line
      }
    },

    /**
     * Send increase session alert to member
     */
    async sendExtendAlert() {
      this.increaseLoading = true
      this.error = null
      try {
        const {
          data: { extendSessionAlert },
        } = await this.$apollo.mutate({
          mutation: EXTEND_SESSION_ALERT,
          variables: {
            extendAlertData: {
              email: this.user.email,
              accesstoken: this.jwt,
              length: this.increaseMin,
              charge: this.increasePrice,
              bookedsessionid: this.session.id,
              tzinfo: this.$defaultTZ,
            },
          },
        })
        if (extendSessionAlert.success) {
          console.log(extendSessionAlert)
          this.increaseMin = 0
          this.increasePrice = 0
        } else {
          this.error = this.$t(extendSessionAlert.error)
        }
        this.increaseLoading = false
      } catch (e) {
        this.increaseLoading = false
        this.extendError = e
        console.log(e) // eslint-disable-line
      }
    },

    /**
     * extend session
     */
    async extendSession() {
      this.extendError = null
      this.extendLoading = true
      try {
        const {
          data: { payAndExtendSession },
        } = await this.$apollo.mutate({
          mutation: PAY_AND_EXTEND_SESSION,
          variables: {
            extensionData: {
              email: this.user.email,
              accesstoken: this.jwt,
              cardId: this.selectedCard.cardid,
              length: this.extendTimeMin,
              charge: this.extendTimePrice,
              bookedsessionid: this.session.id,
              tzinfo: this.$defaultTZ,
            },
          },
        })
        if (payAndExtendSession.success) {
          this.isShowCard = !this.isShowCard
        } else {
          this.extendError = this.$t(payAndExtendSession.error)
        }
        this.extendLoading = false
      } catch (e) {
        this.extendLoading = false
        this.extendError = e
        console.log(e) // eslint-disable-line
      }
    },
    /**
     * mark session completed
     */
    async markedSessionCompleted() {
      try {
        const {
          data: { markSessionCompleted },
        } = await this.$apollo.mutate({
          mutation: MARK_SESSION_COMPLETED,
          variables: {
            sessionData: {
              email: this.user.email,
              accesstoken: this.jwt,
              sessionid: Number(this.session.id),
            },
          },
        })

        if (markSessionCompleted.success) {
        } else {
        }
      } catch (e) {
        this.loading = false
        console.error(e) // eslint-disable-line
      }
    },

    /**
     * Verify the Details
     */
    judge(detail) {
      this.error = `Please enter the ${detail}`
    },

    /**
     * Screen Share
     */
    screenShare() {
      this.isScreenShare = this.isScreenShare ? false : true
      this.disableSessionLists(false)
      this.disableJoin = false
      this.localStream = null
      this.isPermissionError = null
      this.remoteStreams = []
      this.chatDialog = false
      this.sessionSatarted = false
      this.$store.commit('setChatMessages', null)
      window.rtm = null
      this.rtc
        .leaveChannel()
        .then(() => {
          this.joinEvent(true, true, this.isScreenShare)
        })
        .catch(err => {
          log('leave error', err)
        })
      this.disableJoin = true
      this.chatDialog = false
    },

    /**
     * Mute Audio
     */
    muteAudio() {
      this.localStream.isAudioOn()
        ? this.localStream.muteAudio()
        : this.localStream.unmuteAudio()
    },

    /**
     * Mute Video
     */
    muteVideo() {
      this.localStream.isVideoOn()
        ? this.localStream.muteVideo()
        : this.localStream.unmuteVideo()
    },

    /**
     * Alert
     */
    showAlert({ message, isError = false }) {
      this.show = true
      this.text = message
      this.isError = isError
    },

    /**
     * Toggle Screen
     */
    toggleScreen() {
      this.$refs['fullscreen'].toggle()
      this.fullscreen = this.fullscreen ? false : true
      this.enterFullScreen = false
    },

    /**
     * Disable The Session Lists
     */
    disableSessionLists(val) {
      this.$emit('update:is-list-disabled', val)
    },

    /**
     * Move chat scroll to bottom
     */
    scrollToBottom() {
      let chatmessages = document.querySelector('#chatmessages')
      this.$nextTick(() => {
        // DOM updated
        chatmessages.scrollTop = chatmessages.scrollHeight
      })
    },

    /**
     * Show card input for increasing Session
     */
    showCardDetails() {
      if (this.extendTimeMin === 0 && this.extendTimePrice === 0) {
        this.$toasted.error('There is not increase time')
        return
      } else if (this.extendTimeMin != 0 && this.extendTimePrice != 0) {
        this.isShowCard = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#custom-disabled .v-btn--disabled {
  background-color: white !important;
}
.host-video {
  height: 55vh;
}
.client-video {
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 10px;
}
.fullscreen {
  background: none !important;
}
.host-full-video {
  height: 90vh;
}
</style>

<style lang="scss">
.session-time-slot.v-btn-toggle--group > .v-btn.v-btn.v-btn--active {
  background-color: $secondary !important;
  color: $white !important;
  border: none;
}
.session-time-slot.v-btn-toggle--group > .v-btn.v-btn {
  background-color: white !important;
  border: 1px dashed #d9d9d9;
}
.user-card.v-sheet--outlined {
  border: thin solid #ff4200;
}
.v-list.v-select-list .v-list-item--active {
  background-color: aliceblue;
}
.profile-avatar {
  object-fit: cover;
}
.session-countdown {
  color: white;
  position: absolute;
  top: 40%;
  right: 0;
  left: 0;
  z-index: 1000;
  justify-content: center;
}
.session-countdown-time {
  font-size: 40px;
  display: flex;
  justify-content: center;
}
.tooltip-buttons {
  display: flex;
  justify-content: flex-end;
}
.increase_btn {
  background-color: #f7b500;
}
</style>
