<template>
  <div>
    <v-card-title class="text-h3 light-text pa-0 mb-5">
      {{ cardTitle }}
    </v-card-title>
    <template v-if="sessions && sessions.length">
      <div
        v-for="(groupByDateSessions, index) in sessions"
        :key="index"
        class="mt-6 mb-12"
      >
        <v-card-title class="text-h6 pa-0 mb-3">
          {{ groupByDateSessions.date | sessionGRPDate('dddd, D MMMM YYYY') }}
        </v-card-title>
        <div 
          v-for="session in groupByDateSessions.sessions"
          :key="session.index"
        >
          <v-hover
            v-slot="{ hover }"
            close-delay="100"
            open-delay="100"
          >
            <v-card
              :key="session.index"
              active-class="primary session-card-box-shadow"
              :class="{ 'primary session-card-box-shadow' : hover || session.index == defaultSelectedSession.index}"
              class="my-3"
              :disabled="isDisabled"
              flat
              @click="$emit('update:selectedSession', session)"
            >
              <v-list-item two-line>
                <v-list-item-avatar
                  tile
                  class="rounded"
                  size="48"
                >
                  <v-img :src="session[userType].profileImg ? session[userType].profileImg : $defaultImg" />
                </v-list-item-avatar>
                <v-list-item-content
                  class="text-left mt-n3"
                  :class="whiteText(session.index, hover)"
                >
                  <v-list-item-title class="text-subtitle-1 mb-1">
                    {{ session[userType].firstName }} {{ session[userType].lastName }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="text-subtitle-2"
                    :class="whiteText(session.index, hover)"
                  >
                    {{ session.sessionType.title }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  class="text-left text-subtitle-2"
                  :class="whiteText(session.index, hover)"
                  v-text="`${session.sessionType.length} mins./$${session.sessionType.charge}`"
                />
                <v-list-item-title
                  class="text-right text-subtitle-2"
                  :class="whiteText(session.index, hover)"
                >
                  {{ $t("session_at") }} {{ session.startTime | formatDate('hh:mm a') }}
                </v-list-item-title>
              </v-list-item>
            </v-card>
          </v-hover>
        </div>
      </div>
    </template>
    <template v-else>
      <v-card-text class="text-h6 pa-0 pt-5">
        {{ noSessionText }}
      </v-card-text>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    sessions: { 
      type: Array, 
      default: () => [],
      required: true,
    },
    defaultSelectedSession: { 
      type: Object, 
      default: () => {},
      required: true,
    },
    cardTitle: {
      type: String, 
      default: () => null,
      required: true,
    },
    noSessionText: {
      type: String, 
      default: () => null,
      required: true,
    },
    userType: {
      type: String, 
      default: () => null,
      required: true,
    },
    isDisabled: {
      type: Boolean, 
      default: () => false,
      required: true,
    },
  },
  computed: {
    whiteText() {
      return (index, hover) => index == this.defaultSelectedSession.index || hover ? 'white--text' : null
    },
  },
}
</script>

<style lang="scss" scoped>
  .session-card-box-shadow {
    box-shadow: 0 24px 32px 0 $blue_spindle !important;
    transition: box-shadow 200ms ease, margin 200ms ease, color 200ms ease, background-color 200ms ease !important;
  }
</style>